export class ConstantService {

    public static isInitialStart: boolean;
    public static RefreshTokenCall: boolean;

    public static EmptyGuid = '00000000-0000-0000-0000-000000000000';
    public static IsExternalRequest: boolean;
}

export class StorageKeys {
    public static TranslationLanguageKey = 'PORTAL_BONEE_TRANSLATE';
    public static FEEDBACK_DATE_INTERVAL_KEY = 'PORTAL_BONEE_FEEDBACK';
    public static HOT_ACTIONS_HISTORY_DATE_INTERVAL_KEY = 'PORTAL_BONEE_HOT_ACTIONS_HISTORY';
    public static CATALOG_ITEM_GROUP_KEY = 'PORTAL_BONEE_CATALOG_ITEM_GROUP';
    public static CATALOG_ITEM_GROUP_TYPE = 'CATALOG_ITEM_GROUP_TYPE';
    public static ORDERING_DATE_INTERVAL_KEY = 'PORTAL_BONEE_ORDERING_DATE';
    public static ORDERING_PRODUCT_REPORT_DATE_INTERVAL_KEY = 'PORTAL_BONEE_ORDERING_PRODUCT_REPORT_DATE';
    public static CLIENT_DATE_INTERVAL_KEY = 'PORTAL_BONEE_CLIENT_DATE';
    public static COURIER_DATE_INTERVAL_KEY = 'PORTAL_BONEE_COURIER_DATE';
    public static USERDATACHECKED_KEY = 'PORTAL_BONEE_USERDATACHECKED';
    public static SCROLL_DATA_NAME = 'PORTAL_BONEE_ITEMS_SCROLL';
    public static SMART_TRANSLATE_DATA_NAME = 'PORTAL_BONEE_SMART_TRANSLATE_CHECKED';
    public static QR_MENU_GROUP_KEY = 'PORTAL_BONEE_QR_MENU_GROUP';
    public static ORDERING_ACTIVE_CHECKOUT_DATE__INTERVAL_KEY = 'PORTAL_BONEE_ORDERING_ACTIVE_CHECKOUT';
    public static NOTIFICATION_DATE_INTERVAL_KEY = 'PORTAL_BONEE_NOTIFICATION_DATE';
    public static SALE_PORTAL_KEY = 'SALE_PORTAL_KEY';
    public static FROM_SALE_PORTAL = 'FROM_SALE_PORTAL';
    public static SALE_PORTAL_SELECTED_PARTNER = 'SALE_PORTAL_SELECTED_PARTNER';
    public static SALE_PORTAL_SELECTED_PARTNER_FILTER = 'SALE_PORTAL_SELECTED_PARTNER_FILTER';
    public static SALE_PORTAL_TRANSLATE_HOST = 'SALE_PORTAL_TRANSLATE_HOST';
    public static SALE_PORTAL_RESTAURANT_FILTER = 'SALE_PORTAL_RESTAURANT_FILTER';
    public static SALE_PORTAL_RESTAURANT_DATE_INTERVAL_KEY = 'SALE_PORTAL_RESTAURANT_DATE_INTERVAL_KEY';
    public static DIRECTORY_FILTER_GROUP_KEY = 'DIRECTORY_FILTER_GROUP_KEY';
    public static SALE_PORTAL_COMPANY_SELECTED_PARTNER = 'SALE_PORTAL_COMPANY_SELECTED_PARTNER';
    public static SALE_PORTAL_COMPANY_TYPE_KEY = 'SALE_PORTAL_COMPANY_TYPE_KEY';
    public static QR_URLTYPE = 'QR_URLTYPE';
}
